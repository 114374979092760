<template>
  <div class="subPage">
    <div class="subPageTitle">
      <div class="subPageTitleText">
        <p>基差转让挂单</p>
      </div>
      <div class="addButton">
        <el-button v-if="right.add" type="danger" @click="showAddDialog()">新增转让挂单</el-button>
      </div>
    </div>
    <div class="searchList">
      <el-row :gutter="10">
        <el-col :span="7">
           <el-col :span="6"><p class="searchItemTitle">商品分类</p></el-col>
          <el-col :span="18">
            <el-cascader
              class="width-100"
              v-model="selectCategoryOptions"
              :options="categoryOptions"
              :props="categoryProps"
              clearable
            ></el-cascader>
          </el-col>
          <!-- <el-col :span="6">
            <p class="searchItemTitle">商品分类</p>
          </el-col>
          <el-col :span="18">
            <el-input v-model="searchForm.commodityName" placeholder="请输入商品分类"></el-input>
          </el-col> -->
        </el-col>
        <el-col :span="4">
          <el-col :span="6">
            <p class="searchItemTitle">仓库</p>
          </el-col>
          <el-col :span="18">
             <el-select class="width-100" v-model="storageId">
                  <el-option
                    v-for="item in storageIdOptions"
                    :key="item.storageId"
                    :label="item.name"
                    :value="item.storageId"
                  ></el-option>
                </el-select>
          </el-col>
        </el-col>
        <el-col :span="4"></el-col>
        <el-col :span="6">
          <el-button type="danger" @click="getDatas()">搜索</el-button>
          <el-button @click="resetSearch()">重置</el-button>
        </el-col>
      </el-row>
    </div>
    <!-- <el-tabs class="tabList" v-model="activeTab" @tab-click="changeTab">
      <el-tab-pane label="全部" name="all"></el-tab-pane>
      <el-tab-pane label="已上架" name="pass"></el-tab-pane>
      <el-tab-pane label="待上架" name="fail"></el-tab-pane>
      <el-tab-pane label="已撤销" name="cancle"></el-tab-pane>
      <el-tab-pane label="未通过" name="noPass"></el-tab-pane>
    </el-tabs> -->
    <div class="tableList">
      <el-table :data="tableDatas">
        <el-table-column prop="orderId" label="订单ID"></el-table-column>
        <el-table-column prop="className" label="商品分类"></el-table-column>
        <el-table-column prop="specificationsName" label="商品属性">
          <template slot-scope="scope">
            <span>{{ scope.row.specificationsName | firstAttributeTransformate}}</span>
          </template>
        </el-table-column>
        <el-table-column prop="basisPrice" label="基差价">
          <template slot-scope="scope">
            <span>{{ scope.row.basisPrice }}元/{{ scope.row.weightUnitName?scope.row.weightUnitName:'--' }}</span>
          </template>
        </el-table-column>
        <el-table-column prop="hangWeight" label="重量">
           <template slot-scope="scope">
            <span>{{ scope.row.hangWeight }}{{ scope.row.weightUnitName }}</span>
          </template>
        </el-table-column>
        <!-- <el-table-column prop="gpPrice" label="总价"></el-table-column> -->
       <el-table-column prop="storageName" label="交收仓库">
          <template slot-scope="scope">
            <span>{{ scope.row.storageName }}</span>
          </template>
        </el-table-column>
        <el-table-column prop="hangResourcesStatus" label="挂单状态">
          <template slot-scope="scope">
            <span>{{ scope.row.hangResourcesStatus | hangStatusFormate }}</span>
          </template>
        </el-table-column>
         <el-table-column prop="createTime" label="委托时间">
          <template slot-scope="scope">
            <span>{{ scope.row.createTime | DateFormate }}</span>
          </template>
        </el-table-column>
        <el-table-column label="操作" width="180">
          <template slot-scope="scope">
            <el-button @click="showViewDialog(scope.row)" size="mini">查看</el-button>
            <el-button v-if="right.cancel" :disabled="scope.row.hangResourcesStatus=='A'||scope.row.hangResourcesStatus=='U'" @click="cancelResource(scope.row)" type="danger" size="mini">撤销</el-button>
          </template>
        </el-table-column>
      </el-table>
    </div>
    <div class="pagination">
      <el-pagination
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="current"
        :page-size="pageSize"
        layout="total, sizes, prev, pager, next, jumper"
        :total="totalPage"
      ></el-pagination>
    </div>
    <!-- 查看 -->
    <viewDialog ref="viewDialog" />
            <!-- 新增 -->
    <addDialog ref="addDialog" @reLoad="getDatas()" />
  </div>
</template>
<script>
import { mapGetters, mapActions } from "vuex";
import viewDialog from "./viewBasisTransfer";
import addDialog from "./addDialog";
export default {
  data() {
    return {
      activeTab: "all",
      current: 1,
      pageSize: 10,
      totalPage: null,
      tableDatas: [],
      searchForm: {
        commodityName: null,
        warehouse: null,
        statusStr: null,
        orderStatus: null
      },
      right: {
        add: false,
        cancel: false
      },
      selectCategoryOptions:[],
      storageIdOptions: [],
      categoryProps: {
        label: "className",
        value: "classId",
        checkStrictly: false
      },
      categoryOptions:[],
      storageId: null
    };
  },
  computed: {
    ...mapGetters(["sessionInfoGetter"])
  },
  components: {
   addDialog,
    // editDialog,
    viewDialog
  },
  mounted() {
    this.getDatas();
    this.getCommodityCategory();
    this.getStorageIdOptions();
    this.getRights();
  },
  methods: {
    getRights() {
      this.loadRight().then((loadResult) => {
        if (loadResult == 'loadRightSuccess') {
          this.right.add = this.isHasRight('basis-trade-transferOrder');
          this.right.cancel = this.isHasRight('basis-trade-undoHangResources');
        }
      })
    },
    handleSizeChange(val) {
      this.pageSize = val;
      this.getDatas();
    },
    handleCurrentChange(val) {
      this.current = val;
      this.getDatas();
    },
    // 仓库列表
    getStorageIdOptions() {
      http.postFront(protocolFwd.param_getStorage).then(response => {
        const { code, message, value } = response.data;
        if (code == 0) {
          this.storageIdOptions = value;
        } else {
          this.$EL_MESSAGE(message);
        }
      });
    },
    getCommodityCategory() {
      http.getRes(protocolFwd.param_queryVarietiesThree).then(response => {
        const { code, message, value } = response.data;
        if (code == 0) {
          let categoryArr = value
          this.categoryOptions = this.clearChildren(categoryArr);
        } else {
          this.categoryOptions = []
          this.$EL_MESSAGE(message);
        }
      });
    },
    clearChildren(Arr) {
      Arr.forEach(element => {
        if(element&&element.children){
          if(element.children.length==0){
            delete element['children']
          }else{
            this.clearChildren(element.children)
          }
        }
      });
      return Arr
    },
    resetSearch() {
      // this.searchForm = {
      //   commodityName: null,
      //   warehouse: null,
      //   statusStr: null,
      //   orderStatus: null
      // };
      this.selectCategoryOptions = []
      this.storageId = null
      this.getDatas();
    },
    changeTab(tab) {
      this.activeTab = tab.name;
      if (this.activeTab === "all") {
        this.searchForm.statusStr = null;
        this.searchForm.orderStatus = null;
      } else if (this.activeTab === "pass") {
        this.searchForm.statusStr = ["T"];
        this.searchForm.orderStatus =  ["O","B","A"];
      } else if (this.activeTab === "fail") {
        this.searchForm.statusStr = ["Y","P"];
        this.searchForm.orderStatus =  ["O","B","A"];
      } else if(this.activeTab === "cancle") {
         this.searchForm.statusStr = null;
        this.searchForm.orderStatus = ["U"];
      } else if(this.activeTab === "noPass") {
         this.searchForm.statusStr = ["F"];
        this.searchForm.orderStatus =  ["O","B","A"];
      }
      this.getDatas();
    },
    getDatas() {
      protocolFwd.param_queryBasisQueryHangResourceLd.param = {
        page: this.current - 1,
        size: this.pageSize,
        sort: [{property: "createTime", direction: "DESC"}],
        commodityId : this.selectCategoryOptions.length>0?this.selectCategoryOptions[this.selectCategoryOptions.length-1]:null,
        storageId: this.storageId!=null?this.storageId:null,
        firmId: sessionStorage.getItem('userFirmId'),
        sourceId:['F'],
        hangResourceType:null,
        // auditStatus: this.searchForm.statusStr,
        // hangResourcesStatus: this.searchForm.orderStatus
      };
      http.postBasisFront(protocolFwd.param_queryBasisQueryHangResourceLd).then(response => {
        const { code, message, value } = response.data;
        if (code == 0) {
          this.tableDatas = value.content;
          this.totalPage = value.totalElements;
        } else {
          this.$EL_MESSAGE(message);
        }
      });
    },
    showAddDialog() {
      this.$refs.addDialog.showDialog();
    },
    showEditDialog(row) {
      this.$refs.editDialog.showDialog(row);
    },
    showViewDialog(row) {
      this.$refs.viewDialog.showDialog(row);
    },
    cancelResource(row) {
      this.$confirm("请确认是否撤销该基差转让挂单？", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning"
      })
        .then(() => {
          protocolFwd.param_basisUndoHangResources.param.hangResourcesId = row.hangResourcesId;
          http.postFront(protocolFwd.param_basisUndoHangResources).then(response => {
            const { code, message, value } = response.data;
            if (code == 0) {
              this.$EL_MESSAGE(message);
              this.getDatas();
            } else {
              this.$EL_MESSAGE(message);
            }
          });
        })
        .catch(() => {});
    }
  }
};
</script>
<style scoped lang="scss">
.subPage {
  padding: 0 15px;
}
.subPageTitle {
  overflow: hidden;
}
.subPageTitle .subPageTitleText {
  float: left;
  line-height: 40px;
}
.subPageTitle .subPageTitleText p {
  font-size: 16px;
}
.subPageTitle .addButton {
  float: right;
}
.searchList {
  margin: 10px 0;
}
.tabList {
  margin-top: 20px;
}
.tableList {
  margin-top: 20px;
}
.tableList {
  margin: 10px 0;
  /deep/ .el-table thead th{
    background-color: rgba(5, 64, 106, 1);
    color: #fff;
  }
  .allSelectBox{
    line-height: 30px;
    margin-left: 20px;
  }
}
.subPage .pagination {
  margin-top: 15px;
}
.searchItemTitle {
  line-height: 40px;
}
</style>
